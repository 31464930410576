export const theme = {
    colors: {
        primary: "#E76F00", // Primary Color (Orange)
        secondary: "#2D2D2D", // Secondary Color (Black)
        accent: "#a84824", // Accent Color (Light Orange)
        neutralBackground: "#FDFDFD", // Neutral Background (Off-White)
        secondaryBackground: "#F0F0F0", // Secondary Background (Light Grey)
        white: "#F8F9EE", // White
        black: "#000000", // Black
        error: '#FF4C4C',
    },
    fonts: {
        primary: "Roboto, Arial, sans-serif", // Primary Font
        secondary: "Oswald, sans-serif", // Secondary Font
    },
    ui: {
        button: {
            primary: {
                background: "#E76F00",
                color: "#F8F9EE",
            },
            secondary: {
                background: "#F9AA33",
                color: "#2D2D2D",
            },
        },
        input: {
            background: "#FDFDFD",
            border: "#E76F00",
        },
        nav: {
            background: "#2D2D2D",
            color: "#F8F9EE",
            hover: "#E76F00",
        },
    },
};